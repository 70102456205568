import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'The best way to save and share the special moments',
      paragraph: 'Being as simple and elegant as possible, Recollect is the one-stop-shop for all of your memories.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-top">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16 reveal-from-top">
                  <Image
                    src={require('./../../assets/images/feature-tile-icon-03.svg')}
                    alt="Features tile icon 01"
                    width={80}
                    height={80} />
                </div>
              </div>
            </SectionHeader>
            <div className={splitClasses}>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                    Save your memories
                  </h3>
                  <p className="m-0">
                    Recollect makes saving your memories as easy and accessible as possible. Simply input a memory type and a title, any photos or audio, and you're done.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../../assets/images/save.png')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                    Organize your thoughts
                  </h3>
                  <p className="m-0">
                    Easily organize your memories and thoughts, using folders to group together similar memories from trips or events, and starring the important ones.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../../assets/images/organize.png')}
                    alt="Features split 02"
                    width={250} />
                </div>
              </div>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                    View at a glance
                  </h3>
                  <p className="m-0">
                    Simply filter through and view all your memories at a glance for easy recollection. Also recall memories you forgot you had through our suggested filters.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../../assets/images/view.png')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgOddStyle = {
  position: 'absolute',
  width: '118.18%',
  maxWidth: '118.18%',
  top: '-6.31%',
  left: '-16.48%'
}

const imgEvenStyle = {
  position: 'absolute',
  width: '118.18%',
  maxWidth: '118.18%',
  top: '-6.31%',
  left: '-1.51%'
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;