import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import touPDF from "../../../assets/files/terms-of-use.pdf"
import ppPDF from "../../../assets/files/privacy-policy.pdf"

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a target="_blank" href={touPDF}>Terms of Use</a>
        </li>
        <li>
          <a target="_blank" href={ppPDF}>Privacy Policy</a>
        </li>
        <li>
          <a target="_blank" href="mailto:hi@myrecollect.com">Contact</a>
        </li>
        {/* <li>
          <Link to="/about-us/">About us</Link>
        </li>
        <li>
          <Link to="/faqs/">FAQ's</Link>
        </li> */}
        <li>
          <a target="_blank" href="mailto:support@myrecollect.com">Support</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;