import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import Clients from '../components/sections/Clients';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Pricing from '../components/sections/Pricing';
import Testimonial from '../components/sections/Testimonial';

import SectionHeader from '../components/sections/partials/SectionHeader';
import GenericSection from '../components/sections/GenericSection';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

import Cta from '../components/sections/Cta';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSplit invertMobile imageFill className="illustration-section-01" />
        {/* <Clients topDivider bottomDivider /> */}
        <FeaturesSplit bottomDivider imageFill />
        <FeaturesTiles />
        {/* <Pricing pricingSwitcher hasBgColor className="illustration-section-07" />
        <Testimonial className="illustration-section-05" /> */}
        
        <GenericSection topDivider className="illustration-section-06">
          <div className="container-xs">
            <SectionHeader data={{ title: 'Frequently Asked Questions' }} className="center-content" />
            <Accordion>
              <AccordionItem title="How is my data secured?" active>
                Every single memory and folder created in Recollect is encrypted with a secret key, and not readable to any reader's eye.
              </AccordionItem>
              <AccordionItem title="Where is Recollect available?">
                We've recently come out of private beta and released on the <a href="/ios" target="_blank">App Store</a> for iOS, and we also have a <a href="https://app.myrecollect.com" target="_blank">web-based version</a> available as well.
              </AccordionItem>
              <AccordionItem title="How can I get involved?">
                We have a public changelog outlining our progress <a href="https://headwayapp.co/recollect-updates" target="_blank">here</a> and accept feature requests, feedback, and bug reports on our <a href="https://recollect.kampsite.co" target="_blank">feedback board</a>.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>

        <Cta topDivider bottomDivider split className="reveal-from-top" />
      </React.Fragment>
    );
  }
}

export default Home;